import React, { useState, useEffect, useRef } from 'react';
import { get } from 'helpers/utilities';
import classNames from 'classnames/bind';
import ReactPlayer from 'react-player/lazy';
import Image from 'components/Image';
import Parallax from 'components/Parallax';
import Icon from 'components/Icon';
import formatTime from 'helpers/formatTime';
import deviceInfo from 'helpers/deviceInfo';
import Intersect from 'components/Intersect';
import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const imageSizes = {
  largeDesktop: 2000,
  smallDesktop: 1440,
  tablet: 1248,
  mobile: 960
};

const isURLVimeo = url => /https:\/\/vimeo.com\/\d{8,}(?=\b|\/)/.test(url);

const MediaPlayer = React.forwardRef(
  (
    {
      url,
      playing,
      muted,
      onReadyHandler,
      onDurationHandler,
      onProgressHandler,
      onEndedHandler,
      pauseVideo,
      playVideo
    },
    ref
  ) => {
    const [urlWithHash, setUrlWithHash] = useState('');

    useEffect(() => {
      if (!isURLVimeo(url)) return;
      (async () => {
        const videoID = url.split('/').pop();
        const jsonData = {
          id: videoID
        };
        const res = await fetch('/vimeo/fetch', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(jsonData)
        });
        const vimeoData = await res.json();
        setUrlWithHash(vimeoData.data.player_embed_url);
      })();
    }, [url, setUrlWithHash]);

    return (
      <ReactPlayer
        url={isURLVimeo(url) ? urlWithHash : url}
        controls={false}
        playing={playing}
        width="100%"
        height="100%"
        playsinline={true}
        muted={muted}
        volume={1}
        ref={ref}
        config={{
          file: {
            attributes: {
              autoPlay: false
            }
          },
          vimeo: {
            playerOptions: {
              autoplay: false,
              byline: false,
              controls: false
            }
          }
        }}
        onReady={onReadyHandler}
        onDuration={onDurationHandler}
        onProgress={onProgressHandler}
        onEnded={onEndedHandler}
        onPause={pauseVideo}
        onPlay={playVideo}
        loop
      />
    );
  }
);

const MediaEmbed = props => {
  const dataLayer = window.dataLayer || [];
  const { data, className, option, isOdd, theme } = props;
  const {
    caption,
    imageAsset,
    videoAsset,
    enableParallax,
    videoType,
    videoNaviteAsset,
    videoTitle,
    enableVideoAutoplay
  } = data;
  const breakpoint = useBreakpoint();

  const [playing, setPlaying] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [muted, setMuted] = useState(enableVideoAutoplay);
  const [seeking, setSeeking] = useState(false);
  const [isVimeoBasic, setIsVimeoBasic] = useState(false);
  const [videoIntersected, setVideoIntersected] = useState(false);
  const [initialPlayed, setInitialPlayed] = useState(false);
  const [manualPause, setManualPause] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isSafeToPause, setIsSafeToPause] = useState(false);

  const durationEventsInit = {
    limit25: false,
    limit50: false,
    limit75: false,
    limit90: false
  };
  const [durationEvents, _setDurationEvents] = React.useState(durationEventsInit);

  const durationEventsRef = React.useRef(durationEvents);
  const setDurationEvents = durationEventsData => {
    durationEventsRef.current = durationEventsData;
    _setDurationEvents(durationEventsData);
  };

  const playerRef = useRef();
  const controlsRef = useRef();
  const playRef = useRef();
  const smallPlayRef = useRef();

  const isFullWidth = breakpoint === 'mobile' || option === 'fullWidth' ? true : null;
  const video = videoType === 'embedVideos' ? videoAsset : get(videoNaviteAsset, '[0]');
  const videoURL = get(video, 'url');
  const mediaType = video ? 'video' : 'image';
  const parentClass = cx('mediaEmbed', 'toolkit', className, {
    'mediaEmbed--video': mediaType === 'video',
    'mediaEmbed--fullWidth': option === 'fullWidth',
    'mediaEmbed--playing': playing,
    'mediaEmbed--isHovered': ((playing || isPlayed) && isHovered) || deviceInfo.isMobile,
    'mediaEmbed--isPlayed': isPlayed,
    'mediaEmbed--isMuted': muted,
    'mediaEmbed--isEven': !isOdd && mediaType === 'video',
    'mediaEmbed--isOdd': isOdd && mediaType === 'video',
    'mediaEmbed--dark': theme === 'dark'
  });

  let videoTitleContent = get(videoTitle, 'content', false);
  if (videoTitleContent) {
    videoTitleContent = videoTitleContent.replace(/(<([^>]+)>)/gi, '');
  }

  const videoAssetTitle = video ? video?.title : '';
  const cleanVideoTitle = videoTitleContent || videoAssetTitle;

  const adjustDuration = async () => {
    if (duration === 0) {
      const currentDuration = await playerRef.current.getInternalPlayer().getDuration();

      playerRef.current.getInternalPlayer().pause();
      setDuration(currentDuration);
    }
  };

  const fireAnalytics = eventAction => {
    dataLayer.push({
      event: 'video',
      eventCategory: 'html5 video',
      eventAction,
      eventLabel: cleanVideoTitle
    });
  };

  const playVideo = async () => {
    if (!isReady) {
      return;
    }
    // for mobile only
    if (deviceInfo.isMobile && !isVimeoBasic && playerRef?.current?.getInternalPlayer()) {
      await adjustDuration();

      if (!playing) {
        const playPromise = playerRef.current.getInternalPlayer().play();
        playPromise.then(() => {
          setIsSafeToPause(true);
        });
      }
    }

    setIsPlayed(true);
    setPlaying(true);
    fireAnalytics('video play');
  };

  useEffect(() => {
    if (!isReady) {
      return;
    }
    setIsSafeToPause(true);
  }, [playing, isReady]);

  const pauseVideo = async () => {
    if (!isReady) {
      return;
    }

    // for mobile only
    if (deviceInfo.isMobile && !isVimeoBasic && playerRef?.current?.getInternalPlayer()) {
      await adjustDuration();

      if (playing) {
        playerRef.current.getInternalPlayer().playing = false;
      }
    }

    setPlaying(false);
    fireAnalytics('video pause');
  };

  useEffect(() => {
    if (videoURL && videoType === 'embedVideos') {
      (async () => {
        try {
          const res = await fetch(
            `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(videoURL)}`
          );
          const vimeoData = await res.json();
          if (get(vimeoData, 'account_type') === 'basic') {
            setIsVimeoBasic(true);
          }
        } catch (_) {
          setIsVimeoBasic(false);
        }
      })();
    }
  }, [videoURL, videoType]);

  useEffect(() => {
    // Check if the video is in the viewport.
    if (videoIntersected) {
      /**
       * As the video comes into view, play it if:
       *
       * 1. the video has autoplay enabled
       * 2. the video has not played fully once (initialPlayed)
       * 3. the video is currently not playing
       * 4. the video is not manually paused
       */
      if (enableVideoAutoplay && !initialPlayed && !playing && !manualPause) {
        if (deviceInfo.isIOS && deviceInfo.isMobile) {
          setMuted(true);
        }
        playVideo();
      }
    } else if (playing && isSafeToPause) {
      // The video has moved out of view and is playing, pause it.
      pauseVideo();
    }
  }, [videoIntersected, playing, isSafeToPause]);

  function onMouseEnterHandler() {
    setIsHovered(true);
  }

  function onMouseLeaveHandler() {
    setIsHovered(false);
  }

  async function onPlayPauseHandler(e) {
    e.preventDefault();

    if (playing) {
      pauseVideo();
      setManualPause(true);
    } else {
      playVideo();
    }
  }

  const onReadyHandler = player => {
    if (player) {
      setIsReady(true);

      const { videoType } = data;

      if (!(isVimeoBasic || videoType !== 'embedVideos')) {
        const internalPlayer = player.getInternalPlayer();
        if (typeof internalPlayer.pause === 'function') {
          internalPlayer.pause();
          internalPlayer.element.setAttribute('tabindex', '-1');
        }
      }

      if (videoType === 'embedVideos') {
        // Vimeo Required
        setPlaying(false);
        setIsPlayed(false);
      }
    }
  };

  const onEndedHandler = () => {
    fireAnalytics('video_progress_completion');
    setDurationEvents(durationEventsInit);
    setPlaying(false);
    setIsPlayed(false);
    setInitialPlayed(true);
  };

  const onDurationHandler = duration => {
    setDuration(duration);
  };

  function updateDurationEvent(num) {
    if (durationEventsRef.current[`limit${num}`] === false) {
      const analyticsEvent = {
        event: 'video',
        eventCategory: 'html5 video',
        eventAction: `video_progress_${num}`,
        eventLabel: cleanVideoTitle
      };
      dataLayer.push(analyticsEvent);
      const newDurationEvents = {
        ...durationEventsRef.current
      };
      newDurationEvents[`limit${num}`] = true;
      setDurationEvents(newDurationEvents);
    }
  }

  const onProgressHandler = e => {
    const playedDuration = Math.round(e.played * 100);

    if (playedDuration >= 25 && playedDuration < 50) {
      updateDurationEvent(25);
    } else if (playedDuration >= 50 && playedDuration < 75) {
      updateDurationEvent(50);
    } else if (playedDuration >= 75 && playedDuration < 90) {
      updateDurationEvent(75);
    } else if (playedDuration >= 90 && playedDuration < 100) {
      updateDurationEvent(90);
    }

    if (!seeking) {
      setPlayed(e.played);
    }
  };

  function onMutedHandler() {
    setMuted(!muted);
  }

  function onSeekMouseDown() {
    setPlaying(true);
  }

  function onSeekChange(e) {
    setPlayed(parseFloat(e.target.value));
    playerRef.current.seekTo(parseFloat(e.target.value));
  }

  function onSeekMouseUp() {
    setSeeking(false);
  }

  function onFocusHandler() {
    setIsHovered(true);
  }

  function onBlurHandler() {
    setIsHovered(false);
  }

  const renderImageMedia = () => {
    if (option === 'fullWidth' && enableParallax) {
      return (
        <div className={styles.mediaEmbed__parallax}>
          <Parallax
            imageURL={get(imageAsset, '[0].url')}
            imageAlt={get(imageAsset, '[0].alt')}
            lazyload={false}
          />
        </div>
      );
    }
    return (
      <Image
        src={imageAsset[0].url}
        alt={imageAsset[0].title}
        lazyload={false}
        sizes={imageSizes}
      />
    );
  };

  // move focus to play button control once clicked
  function videoButtonClick(e) {
    const vidBtn = (isVimeoBasic ? playRef : smallPlayRef).current;
    onPlayPauseHandler(e);
    setTimeout(() => {
      vidBtn.focus();
    }, 300);
  }

  return (
    <div className={parentClass} data-full-width={isFullWidth}>
      {mediaType === 'image' ? renderImageMedia() : null}
      {mediaType === 'video' ? (
        <Intersect
          config={{ threshold: [0.3, 0.7] }}
          onIntersection={entry => {
            if (entry.length) {
              const [{ isIntersecting }] = entry;

              setVideoIntersected(isIntersecting);
            }
          }}>
          <div
            className={styles.mediaEmbed__video}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}>
            {imageAsset.length ? (
              <div className={styles.mediaEmbed__video__image}>
                <Image
                  src={imageAsset[0].url}
                  alt={imageAsset[0].title}
                  lazyload={false}
                  sizes={imageSizes}
                />
              </div>
            ) : null}
            <MediaPlayer
              url={video.url}
              playing={playing}
              muted={muted}
              ref={playerRef}
              onReadyHandler={onReadyHandler}
              onDurationHandler={onDurationHandler}
              onProgressHandler={onProgressHandler}
              onEndedHandler={onEndedHandler}
              pauseVideo={pauseVideo}
              playVideo={playVideo}
            />
            <aside className={styles.mediaEmbed__video__wrapper}>
              <button
                ref={playRef}
                type="button"
                className={styles.mediaEmbed__video__button}
                onClick={e => videoButtonClick(e)}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                title={cleanVideoTitle}
                aria-label="Play Video"
                tabIndex={isPlayed ? -1 : 0}
                disabled={playing}>
                <Icon name="play" classes={styles.mediaEmbed__video__icon} />
              </button>
              <p className={styles.mediaEmbed__video__label}>{cleanVideoTitle}</p>
            </aside>

            {!isVimeoBasic && (
              <aside className={styles.mediaEmbed__video__controls} ref={controlsRef}>
                <button
                  ref={smallPlayRef}
                  type="button"
                  className={styles.mediaEmbed__video__controls__toggle}
                  onClick={onPlayPauseHandler}
                  aria-label="Toggle video"
                  onFocus={onFocusHandler}
                  onBlur={onBlurHandler}
                  tabIndex={playing || isPlayed ? 0 : -1}>
                  <Icon name="pause" classes={styles.mediaEmbed__video__controls__pause} />
                  <Icon name="play" classes={styles.mediaEmbed__video__controls__play} />
                </button>
                <div className={styles.mediaEmbed__video__controls__time}>
                  <span>{formatTime(played * duration)}</span>
                  <span>{formatTime(duration)}</span>
                </div>
                <div className={styles.mediaEmbed__video__controls__progress}>
                  <input
                    type="range"
                    step="any"
                    min={0}
                    max={1}
                    value={played}
                    onMouseDown={onSeekMouseDown}
                    onChange={onSeekChange}
                    onMouseUp={onSeekMouseUp}
                    aria-valuemin={0}
                    aria-valuemax={1}
                    aria-valuenow={played}
                    aria-label={`${Math.round(played * 100)}% played`}
                    aria-hidden={isPlayed ? 'false' : 'true'}
                    tabIndex={-1}
                  />
                  <span style={{ width: `${played * 100}%` }} />
                </div>
                <button
                  type="button"
                  onClick={onMutedHandler}
                  className={styles.mediaEmbed__video__controls__volume}
                  aria-label="Toggle volume"
                  onFocus={onFocusHandler}
                  onBlur={onBlurHandler}
                  tabIndex={playing || isPlayed ? 0 : -1}>
                  <Icon name="volume" />
                  <Icon name="mute" />
                </button>
              </aside>
            )}
          </div>
        </Intersect>
      ) : null}
      {caption ? (
        <figcaption
          className={styles.mediaEmbed__caption}
          dangerouslySetInnerHTML={{ __html: caption.content }}
        />
      ) : null}
    </div>
  );
};

export default MediaEmbed;
